<template>
  <div
    class="collection-card"
    :data-product-id="product.id"
    @click="addEcommerce('click',product as IProduct)"
  >
    <div
      ref="productImg"
      class="collection-card__media-wrapper"
    >
      <div
        v-if="showSwiper"
        class="collection-card__media slider-images-navigation-button"
        :class="{'disabled-action': isMediaDisabledAction}"
      >
        <div
          ref="collectionSwiper"
          :class="[`class-id-${classId}`]"
          class="collection-swiper swiper"
        >
          <div class="swiper-wrapper">
            <template
              v-for="(imageId, index) in imageIds"
              :key="`product-image-${product.id}-${imageId}`"
            >
              <div class="swiper-slide">
                <div class="swiper-zoom-container">
                  <div class="preload-plug" />
                  <nuxt-link
                    :to="product.url"
                    @click.capture="clickLinkProduct"
                  >
                    <nuxt-img
                      :src="String(imageId)"
                      width="565px"
                      height="397px"
                      fit="default"
                      loading="lazy"
                      :modifiers="{'border': 'default'}"
                      :alt="product.name"
                      decoding="auto"
                      onContextMenu="return false;"
                    />
                  </nuxt-link>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div
          :class="[`next-${classId}`]"
          class="swiper-button-next"
        />
        <div
          :class="[`prev-${classId}`]"
          class="swiper-button-prev"
        />
        <div
          :class="[`pagination-${classId}`]"
          class="swiper-pagination"
        ></div>
      </div>
      <template v-if="Boolean(productsDots.length > 0 && activeSlide === 0)">
        <div class="collection-card__dots">
          <DotProduct
            v-for="(info, index) in productsDots"
            :key="`DotProduct-${index}`"
            :info="info"
          />
        </div>
      </template>
    </div>

    <div class="collection-card__body">
      <div class="collection-card__main">
        <div class="collection-card__name-parent">
          <div class="tooltip">
            <nuxt-link
              :to="product.url"
              class="collection-card__name tooltip__item"
              @click.capture="clickLinkProduct"
            >
              <span v-html="product.name" />
            </nuxt-link>
            <div class="tooltip__hover">
              <span v-html="product.name" />
            </div>
          </div>
        </div>
        <div class="collection-card__composition-price">
          <template
            v-for="(priceIcon, index) in priceCompositionIcons"
            :key="`collection-card__composition-priceIcon-${priceIcon}`"
          >
            <div
              v-if="index > 0"
              class="--sign"
            >
              <img
                loading="lazy"
                src="~/assets/img/svg/common/plus.svg"
                alt="plus"
              />
            </div>
            <div class="--element">
              <nuxt-img
                :src="String(priceIcon)"
                width="16"
                height="16"
                fit="fill"
                loading="lazy"
                decoding="auto"
              />
            </div>
          </template>
          <template v-if="priceCompositionIcons.length > 0">
            <div class="--sign">
              <img
                loading="lazy"
                src="~/assets/img/svg/common/equals.svg"
                alt="plus"
              />
            </div>
          </template>
          <div class="--price">
            {{ $numberFormat(product.price) }} ₽
          </div>
        </div>
      </div>
      <div class="collection-card__delimiter" />
      <div class="collection-card__additionally">
        <div class="collection-card__count-info">
          <div v-if="countChildren">{{ countChildren }}</div>
          <template v-if="countColors">
            <TooltipColors
              :colors="colorsList"
              :product-url="product.url"
            >
              <div>{{ countColors }}</div>
            </TooltipColors>
          </template>
        </div>

        <template v-if="tagsCard.length > 0">
          <div class="collection-card__tags">
            <template
              v-for="(tagCard, index) in tagsCard"
              :key="`collection-card__${index}`"
            >
              <TagItem
                :tag="tagCard"
              />
            </template>
          </div>
        </template>
      </div>
    </div>
    <div class="collection-card__footer">
      <nuxt-link
        :to="`${product.url}#products`"
        class="btn btn-primary"
        @click.capture="clickLinkProduct"
      >Товары коллекции</nuxt-link>
    </div>

    <div class="collection-card__wish">
      <WishBtn :product-id="Number(product.id)" />
    </div>

    <template v-if="Boolean(discountLabel.label)">
      <div
        class="collection-card__promo"
        :class="{'tooltip': discountLabel.tooltip}"
      >
        <div v-html="discountLabel.label" class="tooltip__item"/>
        <div
          v-if="discountLabel.tooltip"
          v-html="discountLabel.tooltip"
          class="tooltip__hover"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useNuxtApp } from 'nuxt/app';
import Swiper from 'swiper';
import { Navigation, Pagination, Virtual, Zoom } from 'swiper/modules';
import { computed, type ComputedRef, ref } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import type { Swiper as SwiperClass } from 'swiper/types';
import type { IProduct, ProductCharacteristic } from '#sitis/internal/controllers/products/models/Product';
import WishBtn from '~/components/widget/WishBtn.vue';
import getDiscountLabel from '~/utils/getDiscountLabel';
import DotProduct from '~/components/catalog/collection-card/DotProduct.vue';
import type { IWishlistItem } from '#sitis/internal/controllers/wish/models/WishlistItem';
import { scrollToElement } from '~/utils/scrollToElement';
import useEcommerce from '~/composables/useEcommerceYandex';
import { sortValuesBigToSmall } from '~/utils/getCharacteristic';
import { getValueFromAbTest } from '~/utils/abTestings';

const TagItem = defineAsyncComponent(() => import('./collection-card/TagItem.vue'));
const TooltipColors = defineAsyncComponent(() => import('./product-card/TooltipColors.vue'));

const { addEcommerce } = useEcommerce();
const props = defineProps<{
	product: IProduct | IWishlistItem;
	isCatalog?: boolean | undefined;
	pageProduct?: number | undefined;
}>();
const $img = useImage();
let classId: string = '';
const {
  $ym,
  $caseWords
} = useNuxtApp();
const { $numberFormat } = useNuxtApp();
const $route = useRoute();
const $router = useRouter();

const activeSlide = ref(0);
const productImg = ref<HTMLDivElement | null>(null);
const showSwiper = ref<boolean>(false);

const collectionSwiper = ref<any>(null);

const imageIds = computed(() => {
  const listIds = props?.product?.imageIds || [];
  return [...listIds].splice(0, 4);
});

const tagsCard = computed(() => {
  const product: any = props?.product;
  const promotions = product?.promotions || [];
  if (promotions.length <= 0) {
    return [];
  }

  return promotions
    .filter((t: any) => Boolean(t?.dynamicFields?.is_additional && t?.iconFileId))
    .sort((a: any, b: any) => {
      const sortA = a?.dynamicFields?.sort || 0;
      const sortB = b?.dynamicFields?.sort || 0;

      if (sortA < sortB) {
        return 1;
      }
      if (sortA > sortB) {
        return -1;
      }
      return 0;
    })
    .map((t: any) => {
      return {
        icon: t?.icon,
        description: t?.description || ''
      };
    });
});
const countColors = computed(() => {
  const collection_color: any = (props?.product?.shortCharacteristics || []).find((t: any) => t.slug === 'collection_color');
  const count = (collection_color?.values || []).length;
  if (count <= 0) {
    return '';
  }
  const word = $caseWords(count, ['цвет', 'цвета', 'цветов']);
  return [count, word].join(' ');
});
const colorsList = computed(() => {
  const collection_color: any = (props?.product?.shortCharacteristics || []).find((t: any) => t.slug === 'collection_color');
  if (!collection_color) {
    return [];
  }

  const brandInternal: any = (props?.product?.shortCharacteristics || []).find((t: any) => t.slug === 'brand-internal')?.values?.[0]?.value;
  const sortValues = sortValuesBigToSmall(collection_color);
  const itemsColors = [...(sortValues?.values || [])]
    .map((_value: any) => {
      let _strValue: string = String(_value?.value || '');
      if (!!brandInternal) {
        _strValue = _strValue.replace(`${brandInternal}: `, '');
      }
      return {
        ..._value,
        value: _strValue
      }
    });

  return itemsColors
});

const countChildren = computed(() => {
  const count = props?.product?.productSetChildrenCount || 0;
  if (count <= 0) {
    return '';
  }
  const word = $caseWords(count, ['предмет', 'предмета', 'предметов']);
  return [count, word].join(' ');
});

const discountLabel = computed(() => getDiscountLabel(props.product));
const productsDots = computed(() => props?.product?.productSetImageDots || []);
const priceCompositionIcons = computed(() => {
  const iconsProductPrice: any = (props.product?.shortCharacteristics || []).find((t: any) => t.slug === 'icons-product-price');
  const value = iconsProductPrice?.values?.[0]?.value || '';
  if (value[0] !== '[' || value[value.length - 1] !== ']') {
    return [];
  }
  return JSON.parse(value);
});
const uuidv4 = () => '10000000'.replace(/[018]/g, (c) => (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16));

const sliderSwiper: any = ref(null);
const sliderInit = () => {
  const res = new Swiper(`.class-id-${classId}`, {
    modules: [Navigation, Pagination, Virtual, Zoom],
    navigation: {
      nextEl: `.next-${classId}`,
      prevEl: `.prev-${classId}`
    },
    pagination: {
      el: `.pagination-${classId}`,
      enabled: true,
      type: 'bullets'
    },
    virtual: {
      cache: true,
      enabled: imageIds.value.length > 3
    },
    loop: true,
    zoom: {
      maxRatio: 6
    },
    breakpoints: {
      1024: {
        zoom: false
      }
    },
    // lazyPreloadPrevNext: 1,
    on: {
      zoomChange: onZoomSlideChange,
      slideChange: (event: SwiperClass) => onSlideChange(event)
    }
  });
  sliderSwiper.value = res;
};

const onSlideChange = (event: any) => {
  activeSlide.value = event?.realIndex || 0;
};

const isMediaDisabledAction: Ref<boolean> = ref(false);
const onZoomSlideChange = (swiper: any, scale: any, imageEl: any, slideEl: any) => {
  if (scale > 1.1) {
    sliderSwiper.value.allowTouchMove = false;
    isMediaDisabledAction.value = true;
  } else {
    sliderSwiper.value.allowTouchMove = true;
    isMediaDisabledAction.value = false;
  };
};

const savePageProduct: Ref<number | null | undefined> = ref(null);
const clickLinkProduct = () => {
  if (typeof $ym === 'function') {
    $ym('reachGoal', 'click_card_collection_button_link');
  }

  if (!props.isCatalog) {
    return;
  }

  const { pathname } = window.location;

  const searchObj: any = $route.query || {};
  searchObj.page = savePageProduct.value;
  let searchStr: any = [];
  Object.keys(searchObj).map((searchKey: string) => {
    searchStr.push(`${searchKey}=${searchObj[searchKey]}`);
  });
  searchStr = searchStr.join('&');

  const newUrl = [pathname, searchStr].filter((t: string) => !!t).join('?');
  $router.options.history.push(newUrl);

  let coockiesData: {} | string = {
    productId: props.product.id,
    fullPath: window.location.pathname
  };
  coockiesData = JSON.stringify(coockiesData);

  const coockyCatalog: any = useCookie('cookie-scroll-catalog');
  coockyCatalog.value = coockiesData;
};
const scrollToProductCatalog = () => {
  if (!props.isCatalog) {
    return;
  }

  const cookieScrollCatalog: any = useCookie('cookie-scroll-catalog');
  if (!cookieScrollCatalog.value) {
    return;
  }

  if (String(cookieScrollCatalog.value?.productId) !== String(props.product?.id)) {
    return;
  }

  const fullPath = window.location.pathname;
  if (fullPath !== cookieScrollCatalog.value?.fullPath) {
    return;
  }

  setTimeout(() => {
    scrollToElement(`[data-product-id="${props.product.id}"]`);
    cookieScrollCatalog.value = null;
  }, 501);
};

const { stop } = useIntersectionObserver(
  productImg,
  async ([{ isIntersecting }]) => {
    if (isIntersecting) {
      showSwiper.value = isIntersecting;

      await nextTick();
      sliderInit();
      stop();
    }
  }
);
onMounted(() => {
  classId = uuidv4();

  if (props.isCatalog) {
    savePageProduct.value = props.pageProduct;
    scrollToProductCatalog();
  }
});
onBeforeUnmount(() => {
  stop();
});
</script>

<style lang="scss">
.collection-card {
	display: flex;
	flex-direction: column;
	border-radius: 20px;
	position: relative;
	z-index: 1;
	background-color: #F4F3F1;

	&:hover {
		z-index: 5;
		border-radius: 20px 20px 0 0;

		.collection-card__footer {
			display: block;
		}
	}

	& .swiper-pagination {
		position: absolute;
	}
}

.collection-card:not(:hover) {
	.collection-card__media-wrapper {
		.collection-dot-product,
		.swiper-button-next,
		.swiper-button-prev,
		.swiper-pagination {
			opacity: 0;
		}
	}
}

.collection-card__wish {
	position: absolute;
	top: 16px;
	right: 16px;
	z-index: 11;
}

.collection-card__media-wrapper {
	display: flex;
	width: 100%;
	position: relative;
	//overflow: hidden;
	border-radius: 20px 20px 5px 5px;

	&::after {
		content: "";
		float: left;
		padding-top: 70.153%;
	}
}

.collection-card__media {
	display: flex;
	width: 100%;
	position: relative;
	overflow: hidden;
	border-radius: 20px 20px 5px 5px;

	& .swiper-button-next {
		right: 0;

		&::after {
			transform: rotate(180deg);
		}

		&:hover {
			background: linear-gradient(-90deg, rgba(0, 0, 0, 0.37) -34.88%, rgba(0, 0, 0, 0) 99.93%);
		}
	}

	& .swiper-pagination-bullet {
		width: 5px;
		height: 5px;
		opacity: 0.5;
		background-color: white;
	}

	& .swiper-pagination-bullet.swiper-pagination-bullet-active {
		opacity: 1;
	}

	& .collection-swiper {
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 20px 20px 5px 5px;
		overflow: hidden;
	}

	& .swiper-slide img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

  &.disabled-action {
    .swiper-button-next,
    .swiper-button-prev,
    .swiper-pagination {
      opacity: 0.2!important;
      pointer-events: none;
    }
  }
}

.collection-card__body {
	padding: 24px;
	box-sizing: border-box;
}

.collection-card__main {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.tooltip {
		display: grid;
		flex: 1;
	}

	.tooltip__hover {
		z-index: 5;
		background-color: white;
		right: initial;
		left: 20px;
    max-width: 220px;
    width: max-content;
		transform: translate(0, -15px);
		border-radius: 15px;
    border-bottom-left-radius: 0;

		&:after {
			right: initial;
			left: 0;
			top: 100%;
      transform: translateY(-10px);
			border-right: 10px solid transparent;
			border-left: 15px solid white;
		}
	}
}

.collection-card__delimiter {
	margin: 12px 0;
	width: 100%;
	height: 1px;
	background-color: #B8B8B8;
	opacity: 0.1;
}

.collection-card__footer {
	display: none;
	padding: 24px;
	padding-top: 0;
	box-sizing: border-box;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	background-color: #F4F3F1;
	border-radius: 0 0 20px 20px;

	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 60px;
		font-weight: 600;
	}
}

.collection-card__name-parent {
  display: flex;
  justify-content: flex-start;
  margin-right: 16px;
}

.collection-card__name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #000;
	font-size: 20px;
	font-weight: 600;
	line-height: 120%;
}

.collection-card__composition-price {
	display: flex;
	align-items: center;

	.--sign {
		margin: 0 4px;
	}

	.--element {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 7px;
		background: white;
		width: 28px;
		height: 28px;
		padding: 6px;
		box-sizing: border-box;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.--price {
		color: #000;
		font-size: 16px;
		font-weight: 600;
		line-height: 100%;
		white-space: nowrap;
	}
}

.collection-card__additionally {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.collection-card__count-info {
	display: flex;
	margin-top: -8px;
	margin-left: -8px;

	& > * {
		border-radius: 7px;
		background: #FFF;
		padding: 1px 16px;
		box-sizing: border-box;
		margin-top: 8px;
		margin-left: 8px;
		color: #000;
		font-size: 12px;
		font-weight: 500;
	}
}

.collection-card__promo {
	position: absolute;
	top: 16px;
	left: 16px;
	z-index: 16;
	border-radius: 10px;
	background: #FFF;
	box-sizing: border-box;
	color: #252525;
	font-size: 12px;
	font-weight: 600;

  .tooltip__item {
    padding: 7px 12px;
  }
  .tooltip__hover {
    background: white;
    bottom: initial;
    top: 100%;
    transform: translateY(10px);
    right: initial;
    left: 0;
    border-radius: 15px;
    border-top-left-radius: 0;

    &:after {
      top: -10px;
      bottom: initial;
      right: initial;
      left: 0;
      border-left: 15px solid white;
      border-right-color: transparent;
    }
  }
}

.collection-card__tags {
	display: flex;
	margin-top: -8px;
	margin-left: -8px;

	& > * {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 28px;
		height: 28px;
		padding: 4px;
		box-sizing: border-box;
		margin-top: 8px;
		margin-left: 8px;
		border-radius: 7px;
		background: #FFF;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}

@media (max-width: 1023px) {
	.collection-card__main {
		flex-direction: column;
		align-items: flex-start;
	}
  .collection-card__name-parent {
    margin-right: 0;
    margin-bottom: 16px;
  }
	.collection-card__name {
		overflow: hidden;
		position: relative;
		width: 100%;
	}
	.collection-card__body {
		padding-bottom: 16px;
	}
	.collection-card__delimiter {
		display: none;
	}
	.collection-card__additionally {
		margin-top: 16px;
	}
	.collection-card__count-info > * {
		padding: 8px 12px;
		box-sizing: border-box;
		line-height: 100%;
	}
	.collection-card__footer {
		display: block;
		position: relative;
		top: 0;

		.btn {
			height: 48px;
			font-size: 12px;
		}
	}
	.collection-card__composition-price .--sign {
		margin: 0 6px;
	}

	.collection-card:not(:hover) {
		.collection-card__media {
			.swiper-button-next,
			.swiper-button-prev {
				display: flex;
				opacity: 1;
			}

			.swiper-pagination {
				display: block;
				opacity: 1;
			}
		}
	}

  .collection-card .collection-card__media .collection-dot-product {
		display: none !important;
	}

  .collection-card__dots {
    display: none;
  }
}

</style>
